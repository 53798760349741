import React, { useState } from 'react';
import { useHistory } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { Formik } from 'formik';
import * as Yup from 'yup';

import EditUserProfile from 'views/EditUserProfile';
import { updateUserProfileThunk } from 'redux/actions/thunks/profile';
import { MPA_ROUTES, ROUTES } from 'routing/constants';
import useMPA from 'hooks/useMPA';
import { useAuth0 } from '@auth0/auth0-react';
import { emailSchema } from 'utilites/schema';

const EditUserProfileContainer = () => {
    const dispatch = useDispatch();
    const { getAccessTokenSilently, logout } = useAuth0();
    const currentUser = useSelector((state) => state.currentUser);
    const { userAvatarUrl, userFirstName, userLastName, userEmail } =
        currentUser;
    const { isCurrentUserMPA } = useMPA();
    const [avatarPreview, setAvatarPreview] = useState(userAvatarUrl);
    const history = useHistory();

    const handleSubmit = (values, { setSubmitting }) => {
        setSubmitting(false);
        let onSuccess = () => {};

        if (isCurrentUserMPA) {
            onSuccess = () => history.push(MPA_ROUTES.userSettings);
        } else {
            onSuccess = () => history.push(ROUTES.userSettings);
        }

        dispatch(
            updateUserProfileThunk(
                getAccessTokenSilently,
                logout,
                values,
                onSuccess
            )
        );
    };

    const initialFormState = {
        avatar: null,
        firstName: userFirstName,
        lastName: userLastName,
        email: userEmail,
    };

    const formSchema = Yup.object().shape({
        firstName: Yup.string()
            .min(2, 'Too short')
            .max(50, 'Too long, maximum characters limit: 50')
            .required('Required'),
        lastName: Yup.string()
            .min(2, 'Too short')
            .max(50, 'Too long, maximum characters limit: 50')
            .required('Required'),
        email: emailSchema.required('Required'),
        avatar: Yup.mixed()
            .nullable()
            .test(
                'size',
                'Selected image is too large. Maximum avatar size: 5MB',
                (value) => {
                    if (!value) return true;
                    return value.size < 5 * 1024 * 1024;
                }
            )
            .test(
                'fileFormat',
                'Invalid avatar format. Available image formats: JPEG, PNG',
                (value) => {
                    if (!value) return true;
                    return ['image/pjpeg', 'image/jpeg', 'image/png'].includes(
                        value.type
                    );
                }
            ),
    });

    return (
        <Formik
            initialValues={initialFormState}
            validationSchema={formSchema}
            onSubmit={handleSubmit}
            enableReinitialize
        >
            {(formikProps) => (
                <EditUserProfile
                    {...currentUser}
                    avatarPreview={avatarPreview}
                    setAvatarPreview={setAvatarPreview}
                    {...formikProps}
                />
            )}
        </Formik>
    );
};

export default EditUserProfileContainer;
