import React, { useEffect, useState } from 'react';
import countryList from 'react-select-country-list';
import apiRoutes from 'config/apiRoutes';
import useFetch from 'hooks/useFetch';
import MPAEditCustomerView from 'views/MPAEditCustomer';
import { useParams } from 'react-router-dom';
import { Formik } from 'formik';
import * as Yup from 'yup';
import Spinner from 'components/Spinner';
import { useDispatch } from 'react-redux';
import {
    dispatchErrorAlertThunk,
    dispatchSuccessAlertThunk,
} from 'redux/actions/thunks/systemAlerts';
import { useAuth0 } from '@auth0/auth0-react';
import { emailSchema } from 'utilites/schema';

const formSchema = Yup.object().shape({
    use_auth0_connection: Yup.boolean(),
    organizationName: Yup.string()
        .min(2, 'Too short')
        .max(50, 'Too long value')
        .required('Required'),
    LMSApiKey: Yup.string()
        .min(2, 'Too short')
        .max(100, 'Too long value')
        .nullable(),
    connectionName: Yup.string()
        .min(2, 'Too short')
        .max(100, 'Too long value')
        .nullable(),
    auth0OrganizationName: Yup.string()
        .min(2, 'Too short')
        .max(100, 'Too long value')
        .nullable(),
    LMSAddress: Yup.string()
        .min(2, 'Too short')
        .max(100, 'Too long value')
        .nullable(),
    performanceCoachingEnabled: Yup.boolean(),
    country: Yup.string().nullable(),
    zipCode: Yup.number()
        .typeError('Invalid zip code')
        .min(501, 'Invalid zip code')
        .max(99950, 'Invalid zip code')
        .nullable(),
    city: Yup.string().min(2, 'Too short').max(50, 'Too long value').nullable(),
    state: Yup.string()
        .min(2, 'Too short')
        .max(50, 'Too long value')
        .nullable(),
    street: Yup.string()
        .min(2, 'Too short')
        .max(50, 'Too long value')
        .nullable(),
    fullName: Yup.string()
        .min(2, 'Too short')
        .max(50, 'Too long value')
        .nullable(),
    mobile: Yup.string()
        .min(2, 'Too short')
        .max(50, 'Too long value')
        .nullable(),
    emailAddress: emailSchema.nullable(),
});

const MPAEditCustomer = () => {
    const { getAccessTokenSilently, logout } = useAuth0();
    const request = useFetch(getAccessTokenSilently, logout);
    const dispatch = useDispatch();
    const { customerId } = useParams();
    const [data, setData] = useState(undefined);
    const [isDropdownVisible, setIsDropdownVisible] = useState(false);

    const handleSubmitSuccess = (res) => {
        if (!res.error) {
            dispatch(
                dispatchSuccessAlertThunk(
                    'organizationUpdated',
                    'Organization updated'
                )
            );
        }
    };

    const handleSubmitError = (err) => {
        dispatch(dispatchErrorAlertThunk(err, err.error));
    };

    const handleSubmit = (values) => {
        const fd = new FormData();
        fd.append(
            'organization[performance_coaching]',
            values.performanceCoachingEnabled
        );
        if (values.LMSAddress || values.LMSAddress === '')
            fd.append('organization[api_url]', values.LMSAddress);
        if (values.LMSApiKey || values.LMSApiKey === '')
            fd.append('organization[api_key]', values.LMSApiKey);
        if (values.connectionName || values.connectionName === '')
            fd.append(
                'organization[auth0_connection_name]',
                values.connectionName
            );
        if (values.auth0OrganizationName || values.auth0OrganizationName === '')
            fd.append(
                'organization[auth0_organization_name]',
                values.auth0OrganizationName
            );
        if (values.organizationName)
            fd.append('organization[name]', values.organizationName);
        if (values.city || values.city === '')
            fd.append('organization[city]', values.city);
        if (values.zipCode || values.zipCode === '')
            fd.append('organization[zipcode]', values.zipCode);
        if (values.state || values.state === '')
            fd.append('organization[state]', values.state);
        if (values.country || values.country === '')
            fd.append('organization[country]', values.country);
        if (values.street || values.street === '')
            fd.append('organization[street]', values.street);
        if (values.fullName)
            fd.append('organization[contact_fullname]', values.fullName);
        if (values.mobile || values.mobile === '')
            fd.append('organization[contact_mobile]', values.mobile);
        if (values.emailAddress || values.emailAddress === '')
            fd.append('organization[contact_email]', values.emailAddress);
        if (values.lmsOrgId)
            fd.append('organization[lms_org_id]', values.lmsOrgId);

        request(
            apiRoutes.put.editCustomerDetails(customerId),
            'PUT',
            handleSubmitSuccess,
            handleSubmitError,
            {
                body: fd,
            }
        );
    };

    const countries = countryList()
        .getData()
        .map((country) => ({
            id: `id_${country.value}`,
            title: country.label,
        }));

    const handleCustomerSuccess = (res) => {
        setData({
            organizationName: res.name,
            LMSApiKey: res.api_key,
            LMSAddress: res.api_url,
            connectionName: res.auth0_connection_name,
            auth0OrganizationName: res.auth0_organization_name,
            performanceCoachingEnabled: res.performance_coaching,
            country: res.country,
            zipCode: res.zipcode,
            city: res.city,
            state: res.state,
            street: res.street,
            fullName: res.contact_fullname,
            mobile: res.contact_mobile,
            emailAddress: res.contact_email,
            lmsOrgId: res.lms_org_id,
        });
    };

    const handleCustomerError = (err) => err;

    useEffect(() => {
        request(
            apiRoutes.get.customerDetails(customerId),
            'GET',
            handleCustomerSuccess,
            handleCustomerError,
            {},
            { 'Content-Type': 'application/json' }
        );
    }, []);

    if (!data) {
        return <Spinner />;
    }

    return (
        <Formik
            initialValues={data}
            validationSchema={formSchema}
            onSubmit={handleSubmit}
            enableReinitialize
        >
            {(formikProps) => (
                <MPAEditCustomerView
                    countries={countries}
                    isDropdownVisible={isDropdownVisible}
                    setIsDropdownVisible={setIsDropdownVisible}
                    {...formikProps}
                />
            )}
        </Formik>
    );
};

export default MPAEditCustomer;
